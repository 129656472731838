/*----------------------
BLog Details Styles
------------------------*/

.blog-single-page-title {
    position: relative;
    h2 {
        font-weight: 700;
        line-height: 90px;
        font-size: 72px;
        @media #{$sm-layout} {
            line-height: 62px;
            font-size: 40px;
        }
    }
    ul {
        &.blog-meta {
            @extend %liststyle;
            margin: 0 -15px;
            flex-wrap: wrap;
            position: absolute;
            width: 100%;
            bottom: -135px;
            li {
                font-size: 16px;
                color: #c6c9d8;
                margin: 0 15px;
                margin-bottom: 13px;
                @media #{$sm-layout} {
                    font-size: 14px;
                }
                svg {
                    margin-right: 13px;
                    font-size: 22px;
                }
            }
        }
    }
}
.rn-blog-details {
    .inner-wrapper {
        .inner {
            padding: 0 110px;
            @media #{$lg-layout} {
                padding: 0 40px;
            }
            @media #{$md-layout} {
                padding: 0 40px;
            }
            @media #{$sm-layout} {
                padding: 0 10px;
            }
            p {
                font-size: 18px;
                line-height: 30px;
                color: rgba(29, 29, 36, 0.75);
                margin-bottom: 40px;
                @media #{$sm-layout} {
                    margin-bottom: 20px;
                }
            }
            .thumbnail {
                margin: 0 -110px;
                @media #{$sm-layout} {
                    margin: 0;
                }
                @media #{$md-layout} {
                    margin: 0;
                }
                img {
                    width: 100%;
                }
            }
            .rn-blog-quote {
                font-size: 24px;
                line-height: 40px;
                color: $heading-color;
                font-weight: 400;
                text-align: center;
                padding: 0 9%;
                margin-top: 67px;
                margin-bottom: 67px;
                position: relative;
                z-index: 2;

                @media #{$lg-layout} {
                    padding: 0 4%;
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
                @media #{$md-layout} {
                    padding: 0 4%;
                    margin-top: 40px;
                    margin-bottom: 40px;
                }

                @media #{$sm-layout} {
                    padding: 0 4%;
                    margin-top: 40px;
                    margin-bottom: 40px;
                    font-size: 20px;
                    line-height: 33px;
                }
                @media #{$large-mobile} {
                    padding: 0;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    font-size: 16px;
                    line-height: 29px;
                }

                &::before {
                    position: absolute;
                    width: 234px;
                    height: 121%;
                    left: 50%;
                    top: 2px;
                    content: "";
                    opacity: 1;
                    background-repeat: no-repeat;
                    z-index: -1;
                    transform: translateX(-50%);
                }
            }
        }
        .blog-single-list-wrapper {
            margin-right: -110px;

            @media #{$sm-layout} {
                margin-right: 0;
            }

            @media #{$md-layout} {
                margin-right: 0;
            }
            .thumbnail {
                width: 55%;
                padding-right: 30px;
                margin-right: 0;
                @media #{$sm-layout} {
                    width: 100%;
                    padding-right: 0;
                }
                @media #{$md-layout} {
                    width: 100%;
                    padding-right: 0;
                }
                img {
                    width: 100%;
                }
                span {
                    color: rgba(29, 29, 36, 0.75);
                    font-size: 14px;
                    display: block;
                    text-align: left;
                    padding-top: 10px;
                    padding-left: 110px;
                    @media #{$sm-layout} {
                        font-size: 13px;
                        padding-left: 0;
                        margin-bottom: 23px;
                    }
                }
            }
            h4{
                &.title{
                    font-size: 24px;
                    font-weight: 500;
                    margin-bottom: 30px;
                    @media #{$sm-layout} {
                        margin-bottom: 16px;
                    }
                }
            }
            .content {
                width: 45%;

                @media #{$sm-layout} {
                    width: 100%;
                }
                @media #{$md-layout} {
                    width: 100%;
                }
                ul {
                   &.list-style {
                       margin-bottom: 50px;
                        @media #{$sm-layout} {
                            margin-bottom: 25px;
                        }
                   }
                }
            }
        }
    }
}


/*------------------------
    BLog Contact Form
--------------------------*/
.blog-comment-form {
    .inner {
        .rnform-group {
            margin-bottom: 20px;
            input {
                border: 2px solid #e9e9e9;
                border-radius: 4px;
                height: 50px;
                font-size: 16px;
            }
            textarea {
                min-height: 193px;
                border: 2px solid #e9e9e9;
                border-radius: 4px;
                resize: none;
                padding: 15px;
                font-size: 16px;
            }
        }
    }
}

/*------------------------
    Background Videos
--------------------------*/
.embed-responsive {
    margin: auto !important;
    width: 100%;
}

.embed-responsive iframe {
	min-height: 490px;
	width: 100%;
}

.embed-responsive-top {
    margin: auto !important;
    width: 100%;
}

.embed-responsive-top iframe {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -999;
}

.embed-responsive-small {
    margin: auto !important;
    width: 90%;
}

.embed-responsive-small iframe {
	min-height: 490px;
	width: 100%;
}

@media(max-width:640px) {
	.embed-responsive iframe {
		min-height: 320px;
		width: 100%;
	}

    .embed-responsive-full iframe {
		min-height: 320px;
		width: 100%;
	}
}


/*------------------------
    LightBox
--------------------------*/
.ril__captionContent {
    margin: auto;
}

.text-block-quote {
    font-style: normal !important;
    text-align: left !important;
    margin-top: 0px !important;
    margin-bottom: 25px !important;
}

.text-block-quote::before {
    background-image: none !important;
}





/*------------------------
    Customizations
--------------------------*/
.swiper-button-next, .swiper-button-prev {
    color: #fff !important;
  }

.centered {
    font-size: 24px;
    line-height: 40px;
    color: white;
    font-weight: 400;
    font-style: italic;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
}

.centered-first-heading {
    transform: translate(-50%, +1500%);
}

.centered p {
    font-size: 24px;
    line-height: 40px;
}

.rn-blog-quote{
    min-height: 8rem;
}

.card-overlay {
    background: rgba(0, 0, 0, 10);
}

.swiper-slide img{
    border-radius: 5px;
}

.bkg {
    display: flex;
    align-items: stretch;
    justify-content: center;
    max-height: 520px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
}

.vid  {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.small-centered-img {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 50% !important;
}

.medium-centered-img {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 75% !important;
}

.centered-text {
    text-align: center;
}

.fullWidth {
    width: 100% !important
}

.map-section-content ul {
    padding: 0;
    list-style-type: none;
    width: 75%;
    margin-right: auto;
    margin-left: auto;
}

.map-section-content a {
    background: linear-gradient(120deg, var(--color-secondary) 20.69%, var(--color-primary) 50.19%, #13595D 79.69%);
    background-clip: border-box;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-primary)', endColorstr='var(--color-secondary)',GradientType=1 );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.portfolio-static .thumbnail a img {
    width: 100%;
}

.sidebar {
    position: absolute;
    margin-top: 22px;
    margin-bottom: 20px;
    margin-right: 22px;
    top: 0;
    right: 0;
    width: 30%;
    height: 94%;
    background-color: white;
    border-left: 1px solid #ccc;
    overflow-y: auto;
}

.image-gif {
    margin-top: 120px;
}

.gif_player img {
    width: 100%;
}

.image-gif-centered {
    display: block;
    margin: 0 auto;
    max-width: 100%; /* Default for mobile */
}

/* Tablet view */
@media (min-width: 600px) and (max-width: 1024px) {
    .image-gif-centered {
        max-width: 80%;
    }
}

/* Desktop view */
@media (min-width: 1025px) {
    .image-gif-centered {
        max-width: 40%;
    }
}

/* Ensure this CSS applies the style to larger viewports only */
@media (max-width: 1024px) {
    div.switch-container {
        display: none !important;
    }
}

.no-hover {
    text-decoration: none;
    color: inherit;
}

.no-hover:hover,
.no-hover:focus {
    text-decoration: none;
    color: #990000;
    background: none;
    pointer-events: auto;
}

.side-window .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background-color: #fff;
    border: 0.5px solid #ccc;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 1px 2.5px rgba(0, 0, 0, 0.2);
}

.side-window .close-button:hover {
    background-color: #f8f8f8;
    border-color: #888;
}

