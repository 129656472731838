.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 68%;
  height: 92%;
  background: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1001;
}

.modal-image {
  max-width: 70%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 15px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal-overlay-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  border: none;
  z-index: 1;
}

.modal-description {
  max-width: 100%;
  text-align: justify;
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  max-height: 200px;
  overflow-y: auto;
}

.modal-heading {
  padding-bottom: 20px;
}

.modal-content iframe {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
}

@media (min-width: 769px) {
  .modal-description {
    margin-top: 65px;
  }
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    height: auto;
    padding: 15px;
  }

  .modal-image {
    max-width: 90%;
  }

  .modal-description {
    font-size: 14px;
    max-height: 150px;
    margin-top: 0;
  }

  .close-modal {
    font-size: 20px;
  }

  .modal-heading {
    padding-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    height: auto;
  }

  .modal-image {
    max-width: 95%;
  }

  .modal-description {
    font-size: 12px;
    max-height: 120px;
    margin-top: 0;
  }

  .close-modal {
    font-size: 18px;
  }

  .modal-heading {
    padding-bottom: 10px;
  }
}
