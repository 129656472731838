#map { position: absolute; top: 0; bottom: 0; width: 100%; }

#map {
    position: fixed;
    width: 100%;
    z-index: -1;
}

#features {
  width: 50%;
  margin-left: 25%;
  font-family: sans-serif;
  overflow-y: hidden;
  background-color: rgba(255, 255, 255, 0);

    @media #{$lg-layout} {
        width: 90%;
        margin-left: 5%;
        h3 {
            font-size: var(--h3);
            text-align: center;
        }
    }
    @media #{$md-layout} {
        width: 90%;
        margin-left: 5%;
        h3 {
            font-size: var(--h3);
            text-align: center;
        }
    }
    @media #{$sm-layout} {
        width: 90%;
        margin-left: 5%;
        h3 {
            font-size: var(--h3);
            text-align: center;
        }
    }
    @media #{$large-mobile} {
        width: 90%;
        margin-left: 5%;
        h3 {
            font-size: var(--h3);
            text-align: center;
        }
    }
}

section {
    padding: 25px 50px;
    line-height: 25px;
    opacity: 0.25;
    font-size: 13px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    color: rgb(0, 0, 0);
    margin-bottom: 800px;

    @media #{$lg-layout} {
        padding: 5px 10px;
    }
    @media #{$md-layout} {
        padding: 5px 10px;
    }
    @media #{$sm-layout} {
        padding: 5px 10px;
    }
    @media #{$large-mobile} {
        padding: 5px 10px;
    }
}

section.active {
  opacity: 1;
}

.large-centered-img {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
    border-radius: 3px;
}