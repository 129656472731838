.tooltip {
    position: relative;
    display: inline-block !important;
    border-bottom: 1px dotted black;
    opacity: 1 !important;
    background: var(--color-primary);
    background: linear-gradient(120deg, var(--color-secondary) 20.69%, var(--color-primary) 50.19%, #13595D 79.69%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--color-primary)', endColorstr='var(--color-secondary)',GradientType=1 );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    position: absolute;
    background-color: rgb(88, 84, 84);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 2px;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    -webkit-text-fill-color: #fff;
    margin-left: -60px;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 24%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
  }